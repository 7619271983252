import React from 'react';

const ContactFocus = () => {

    const openGoogleForm = () => {
      window.open('https://docs.google.com/forms/d/e/1FAIpQLSclV7Vy87VlJQ6E5hcsoiCZcSigjH0L_NONDNxQn5lxasIOfw/viewform', '_blank', 'noreferrer');
    }

    return (
        <section className="section section-padding-equal bg-color-light" id="contact-form">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-12">
                        <div className="about-us">
                            <div className="section-heading heading-left mb-0">
                                <div className="contact-form-box">
                                    <h3 className="title">¿Deseas que te contactemos?</h3>
                                    <p>Déjanos tus datos y un miembro de nuestro equipo se pondrá en contacto contigo a través de WhatsApp o llamada telefónica.</p>
                                    <div className="contact-form-btn" type="submit" onClick={openGoogleForm}>
                                      <span role="img" aria-label='pointing-right'>👉</span> Llenar formulario <span role="img" aria-label='pointing-left'>👈</span>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="shape-group-6 list-unstyled">
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="line" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="line" /></li>
            </ul>
        </section>
    )
}

export default ContactFocus;