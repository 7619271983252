import React from 'react';

const BcrumbBannerFocus = ({serviceDetails}) => {
  const {
    title,
    description,
    items
  } = serviceDetails
    return (
        <div className="breadcrum-area breadcrumb-banner">
            <div className="container">
                <div className="section-heading heading-left">
                    <h1 className="title h2" dangerouslySetInnerHTML={{__html: title}}></h1>
                    <p dangerouslySetInnerHTML={{__html: description}}></p>
                </div>
                <div className="col-lg-6 col-md-9 col-sm-12 mb--30">
                  <ul className="list-style services-list">
                    {items && items.map((item, index) => (
                      <li key={index}>{item.description}</li>
                    ))}
                  </ul>
                </div>
            </div>
            <ul className="shape-group-8 list-unstyled">
                <li className="shape shape-1">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-9.png"} alt="Bubble" />
                </li>
                <li className="shape shape-3">
                    <img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Line" />
                </li>
            </ul>
        </div>
    )
}

export default BcrumbBannerFocus;