import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Link } from 'react-router-dom';

const BannerFocus = () => {

    const scrollToBottom = () => {
      window.scrollTo({ top: document.body.scrollHeight - 1000, behavior: 'smooth' })
    }
    return (
        <div className="banner banner-style-1">
            <div className="container">
                <div className="row align-items-end align-items-xl-start">
                    <div className="col-lg-6">
                        <div className="banner-content">
                            <AnimationOnScroll animateIn="fadeInUp" animateOnce={true} delay={100}>
                                <h3 className="title">Administramos tu OnlyFans <span role="img" aria-labelledby="love">🍑</span> y te ayudamos a aumentar el número de seguidores <span role="img" aria-labelledby="love">🚀</span> para que ganes más dinero <span role="img" aria-labelledby="love">💵💵💵</span>.</h3>
                                <span className="subtitle"><span role="img" aria-labelledby="love">✨</span><span role="img" aria-labelledby="love">✨</span> Tú determinas tus límites, tú determinas tu futuro. <span role="img" aria-labelledby="love">✨</span><span role="img" aria-labelledby="love">✨</span></span>
                                <Link
                                  to={process.env.PUBLIC_URL + "/"}
                                  className="axil-btn btn-fill-primary btn-fluid btn-primary"
                                  onClick={scrollToBottom}
                                >
                                  ¡Comienza ahora!
                                </Link>
                            </AnimationOnScroll>
                        </div>
                    </div>
                    {/* <div className="col-lg-6">
                        <div className="banner-thumbnail">
                            <AnimationOnScroll animateIn="zoomIn" duration={2} delay={300} animateOnce={true}>
                                <div className="large-thumb">
                                    <img src={process.env.PUBLIC_URL + "/images/banner/sexy-girl-blue.png"} alt="Laptop" />
                                </div>
                            </AnimationOnScroll>
                            <AnimationOnScroll animateIn="slideInRight" duration={2} delay={300} animateOnce={true}>
                            <div className="large-thumb-2">
                                <img src={process.env.PUBLIC_URL + "/images/banner/woman-posing.png"} alt="Laptop" />
                            </div>
                            </AnimationOnScroll>

                        </div>
                    </div> */}
                </div>
            </div>
            <ul className="list-unstyled shape-group-21">
                <li className="shape shape-2">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-38.png"} alt="Bubble" />
                </li>
                <li className="shape shape-4">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
                </li>
                <li className="shape shape-5">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
                </li>
                <li className="shape shape-6">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
                </li>
                <li className="shape shape-7">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-41.png"} alt="Bubble" />
                </li>
            </ul>
        </div>
    )
}

export default BannerFocus;