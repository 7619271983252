import React, { useState } from 'react';
import OffcanvasMenu from './OffcanvasMenu';
import StickyHeader from './StickyHeader';
import MobileMenu from './MobileMenu';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram } from "react-icons/fa";

const HeaderFocus = () => {

    const [showOffcanvas, setShowOffcanvas] = useState(false);

    const OffcanvasHandleClose = () => setShowOffcanvas(false);

    const sticky = StickyHeader(100);

    const MobileMenuHandler = () => {
        document.querySelector('.mobilemenu-popup').classList.toggle("show");
        document.querySelector('body').classList.toggle("mobilemenu-show");

        var elements = document.querySelectorAll('.mobilemenu-popup .menu-item-has-children > a');
    
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.axil-submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
    }

    return (
        <>
          <header className="header axil-header header-style-1">
              <div className={`axil-mainmenu ${sticky ? "axil-sticky" : ""}`}>
                  <div className="container">
                      <div className="header-navbar">
                          <div className="header-logo">
                            <Link to={process.env.PUBLIC_URL + "/"}>
                              <img className="focus-house-logo" src={process.env.PUBLIC_URL + '/images/LogoHome.svg'} alt="logo" />
                            </Link>
                          </div>
                          <div className="header-main-nav">
                              <div className="footer-top">
                                <div className="footer-social-link">
                                    <ul className="list-unstyled social-header-list">
                                      <li>
                                        <a href="https://www.instagram.com/focus.house.agency/" target='_blank' rel="noopener noreferrer">
                                          <FaInstagram />
                                        </a>
                                      </li>
                                      <li>
                                        <a href="https://www.facebook.com/thefocushouseagency/" target='_blank' rel="noopener noreferrer">
                                          <FaFacebook />
                                        </a>
                                      </li>
                                    </ul>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </header>
          <OffcanvasMenu offcanvasShow={showOffcanvas} offcanvasHide={OffcanvasHandleClose} />
          <MobileMenu MobileHandler={MobileMenuHandler}/>
        </>
    )
}

export default HeaderFocus;