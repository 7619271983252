import React from 'react';
import {useParams} from 'react-router-dom';
import FooterFocus from '../common/footer/FooterFocus';
import HeaderFocus from '../common/header/HeaderFocus';
import BcrumbBannerFocus from '../elements/breadcrumb/BcrumbBannerFocus';
import { slugify } from '../utils';
import ServiceData from "../data/service/ServicesFocus.json";
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';

const allServiceData = ServiceData;

const ServiceDetailsFocus = () => {

    const params = useParams();
    const serviceSlug = params.slug;

    const getServiceData = allServiceData.filter(data => slugify(data.title) === serviceSlug);
    const serviceDetails = getServiceData[0];

    return (
        <>
        <SEO title={serviceDetails.title} />
        <ColorSwitcher />
        <main className="main-wrapper">
          <HeaderFocus />
          <BcrumbBannerFocus
            serviceDetails={serviceDetails}
            title={serviceDetails.title}
            paragraph ={serviceDetails.description}
            items={serviceDetails.items}
          />
          <FooterFocus />
        </main>
        </>
    )
}

export default ServiceDetailsFocus;