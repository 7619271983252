import React from 'react';

const FooterFocus = () => {

    return (
        <footer className="footer-area footer-dark">
            <div className="container">
                <div className="footer-bottom">
                <div className="row">
                    <div className="col-md-6">
                    <div className="footer-copyright">
                        <span className="copyright-text">© {new Date().getFullYear()}. Todos los derechos reservados <strong>Focus Agency</strong>.</span>
                    </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="footer-bottom-link">
                          <ul className="list-unstyled">
                              <li><Link to={"#"}>Politica de privacidad y tratamiendo de datos.</Link></li>
                          </ul>
                      </div>
                    </div> */}
                </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterFocus;