import React from 'react';
import FooterFocus from '../common/footer/FooterFocus';
import HeaderFocus from '../common/header/HeaderFocus';
import SEO from '../common/SEO';
import BannerFocus from '../component/banner/BannerFocus';
import ServicesFocus from '../component/service/ServicesFocus';
import SectionTitle from '../elements/section-title/SectionTitle';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import ContactFocus from '../component/contact/ContactFocus';

const Focus = () => {

    return (
        <>
        <SEO title="Focus House Agency"/>
        <ColorSwitcher />
        <main className="main-wrapper">
            <HeaderFocus />
            <BannerFocus />
            <div className="section section-padding-2 bg-color-dark">
                <div className="container">
                    <SectionTitle 
                        subtitle="¿Cómo te podemos ayudar?"
                        title="Estos son nuestros servicios"
                        description="Logra tus objetivos y metas personales gracias a los servicios que tenemos para ti."
                        textAlignment="heading-light-left"
                        textColor=""
                    />
                    <div className='row'>
                        <ServicesFocus colSize="col-xl-6 col-md-6" serviceStyle="" itemShow="4" />
                    </div>
                </div>
                <ul className="list-unstyled shape-group-10">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-9.png"} alt="Circle" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-42.png"} alt="Circle" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-43.png"} alt="Circle" /></li>
                </ul>
            </div>
            <ContactFocus id="contact-form" />
            <FooterFocus />
        </main>
        </>
    )
}

export default Focus;

